import React, { useEffect, useRef, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon, FormStatusIndicator } from '@src/components/atoms';
import { BackNavigator, ThemeButton, Tooltip } from '@src/components/molecules';
import { FormsForm } from '@src/components/shared';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { formState, submitFormState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import { FormStatus, QuestionType } from '@src/__generated__/globalTypes';

const AddForm = () => {
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const previewButtonRef = useRef<HTMLButtonElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  // form is able to draft edit/preview without saving, using recoil to save the draft information
  const { recoilState: formInformation, setRecoilState: setFormState } = useRecoil(formState);
  const { setRecoilState: setSubmitFormState } = useRecoil(submitFormState);
  const { i18n, pathname, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const isJPLanguage = i18n.language === 'ja';
  const formDetails = {
    description: '',
    hash: uuidv4(),
    questions: [
      {
        genId: uuidv4(),
        image: null,
        isRequired: true,
        options: [],
        order: 1,
        questionType: QuestionType.NAME,
        title: '',
      },
      {
        genId: uuidv4(),
        image: null,
        isRequired: true,
        options: [],
        order: 2,
        questionType: QuestionType.EMAIL,
        title: '',
      },
      {
        genId: uuidv4(),
        image: null,
        isRequired: false,
        options: [],
        order: 3,
        questionType: QuestionType.SHORT_ANSWER,
        title: '',
      },
    ],
    status: FormStatus.DRAFT,
    thankDescription: isJPLanguage
      ? 'ご回答をいただき、誠にありがとうございます。お問い合わせの内容を確認し次第、担当者より今後の対応についてご連絡させていただきます。'
      : 'Thank you very much for your response. As soon as we confirm the content of your inquiry, our staff will contact you regarding our future actions.',
    thankTitle: isJPLanguage ? 'ご回答ありがとうございます！' : 'Your response has been recorded',
    title: '',
  };
  const { description, hash, questions, status, thankDescription, thankTitle, title } = formDetails;
  const tabs = [{ text: 'Entry', to: ROUTES.MY_PAGE_FORMS_ADD }];
  // check if current form is in drafting; if yes, using draft information instead of DB information
  const isEditing = !!formInformation?.hash;

  useEffect(() => {
    // to reset the draft information if the information is not related to current selected form
    if (!isEditing) {
      setFormState(null);
      setSubmitFormState(null);
    }
  }, []);

  const onClickBack = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();

    return backButtonRef.current?.click();
  };

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <div>
            <BackNavigator title="Add Form" to={ROUTES.MY_PAGE_FORMS} onClick={onClickBack} />
            <FormStatusIndicator status={FormStatus.DRAFT} />
          </div>
          <div>
            <Tooltip help="preview">
              <div css={styles.previewButton} onClick={() => previewButtonRef.current?.click()}>
                <Icomoon icon="visible" />
              </div>
            </Tooltip>
            <ThemeButton
              theme="blue"
              css={styles.saveBtn}
              text="Save"
              onClick={() => submitButtonRef?.current?.click()}
            />
          </div>
        </div>
      )}

      <div css={styles.infoContainer}>
        <div css={styles.tabsContainer}>
          {tabs.map(tab => {
            const { text, to } = tab;
            const isActive = pathname.includes(to);

            return (
              <Tab isActive={isActive} key={to} to={to}>
                {t(text)}
              </Tab>
            );
          })}
        </div>
        <NoAvailableFeatureContent isVisible={isMobileView}>
          <div>
            <div>{t('Annotation.This feature is only available on PCs')}</div>
            <Link to={ROUTES.MY_PAGE_FORMS}>{t('Anchor.Back')}</Link>
          </div>
        </NoAvailableFeatureContent>
        <ContentContainer isVisible={!isMobileView}>
          <FormsForm
            description={isEditing ? formInformation?.description || '' : description}
            hash={hash}
            isFormAnswerSubmitted={false}
            questions={isEditing ? formInformation?.questions || [] : questions}
            refs={{
              backButtonRef,
              previewButtonRef,
              submitButtonRef,
            }}
            status={isEditing ? formInformation?.status || FormStatus.DRAFT : status}
            thankDescription={isEditing ? formInformation?.thankDescription || '' : thankDescription}
            thankTitle={isEditing ? formInformation?.thankTitle || '' : thankTitle}
            title={isEditing ? formInformation?.title || '' : title}
          />
        </ContentContainer>
      </div>
    </div>
  );
};

const ContentContainer = styled.div<{ isVisible: boolean }>(({ isVisible }) => ({
  backgroundColor: THEME.colors.white,
  padding: 24,
  ...(!isVisible && { display: 'none' }),
}));

const NoAvailableFeatureContent = styled.div<{ isVisible: boolean }>(({ isVisible }) => ({
  alignItems: 'center',
  backgroundColor: THEME.colors.white,
  borderRadius: '0 0 5px 5px',
  boxShadow: THEME.box.shadows.outer,
  display: isVisible ? 'flex' : 'none',
  height: 88,
  justifyContent: 'center',
  textAlign: 'center',

  '& > div': {
    '& > div:nth-of-type(1)': {
      color: '#000',
      fontSize: THEME.font.sizes.normal,
      marginBottom: 8,
    },

    '& > a': {
      color: THEME.font.colors.blue.main,
      fontSize: THEME.font.sizes.normal,

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const Tab = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isActive: boolean }>(({ isActive }) => ({
  borderBottom: `4px solid ${isActive ? '#3892e5' : '#fff'}`,
  color: THEME.font.colors.black.main,
  cursor: 'pointer',
  fontSize: THEME.font.sizes.normal,
  fontWeight: isActive ? 600 : 400,
  margin: '0 16px',
  padding: '16px 0',
  width: 'fit-content',
}));

const styles = {
  container: css({
    display: 'grid',
    padding: '16px 24px',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: 16,
    },
  }),
  infoContainer: css({
    justifySelf: 'center',
    marginTop: 16,
    maxWidth: 648,
    width: '100%',
  }),
  navigatorContainer: css({
    display: 'flex',
    flexWrap: 'wrap',

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      flexBasis: '50%',

      '& > div:nth-of-type(2)': {
        alignItems: 'center',
        backgroundColor: '#ffb63d',
        borderRadius: 35,
        color: THEME.font.colors.white,
        display: 'flex',
        fontSize: THEME.font.sizes.subordinate,
        fontWeight: 600,
        height: 24,
        justifyContent: 'center',
        marginLeft: 8,
        padding: '0 16px',
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexBasis: '50%',
      justifyContent: 'flex-end',
    },
  }),
  previewButton: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    border: THEME.box.borders.gray.main,
    borderRadius: 3,
    cursor: 'pointer',
    display: 'flex',
    height: 32,
    justifyContent: 'center',
    marginRight: 10,
    width: 32,
  }),
  saveBtn: css({
    fontSize: THEME.font.sizes.subordinate,
    width: 'max-content',
  }),
  tabsContainer: css({
    backgroundColor: THEME.colors.white,
    borderBottom: '1px solid #edf2f5',
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 8px',
  }),
};

export default AddForm;
