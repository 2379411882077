import React from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { EmailStatusIndicator } from '@src/components/atoms';
import { BackNavigator } from '@src/components/molecules';
import { useGetEmailTemplateQuery } from '@src/graphql/hooks';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { generatePath, ROUTES } from '@src/shared/routes';
import Overview from './Overview';
import Recipient from './Recipient';

const Reports = () => {
  const { params, pathname, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const emailId = params.id as string;
  const { data } = useGetEmailTemplateQuery({
    variables: {
      input: {
        id: Number(emailId),
      },
    },
  });
  const tabs = [
    { title: 'Overview', to: generatePath(ROUTES.MY_PAGE_EMAILS_EDIT_OVERVIEW, { id: emailId }) },
    { title: 'Recipient', to: generatePath(ROUTES.MY_PAGE_EMAILS_EDIT_RECIPIENT, { id: emailId }) },
  ];
  const isOverviewTab = pathname === generatePath(ROUTES.MY_PAGE_EMAILS_EDIT_OVERVIEW, { id: emailId });

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="Email Report" to={ROUTES.MY_PAGE_EMAILS} />
          {data?.getEmailTemplate?.status && <EmailStatusIndicator status={data.getEmailTemplate.status} />}
        </div>
      )}

      <div css={styles.infoContainer}>
        <div css={styles.tabsContainer}>
          {tabs.map((tab, index) => {
            const { title, to } = tab;
            const isActive = pathname.includes(to);

            return (
              <Tab isActive={isActive} key={index} to={to}>
                {t(title)}
              </Tab>
            );
          })}
        </div>

        {isOverviewTab ? (
          <Overview emailId={emailId} />
        ) : isMobileView ? (
          <div css={styles.noAvailableFeatureContent}>
            <div>
              <div>{t('Annotation.This feature is only available on PCs')}</div>
              <Link to={ROUTES.MY_PAGE_EMAILS}>{t('Anchor.Back')}</Link>
            </div>
          </div>
        ) : (
          <Recipient emailId={emailId} />
        )}
      </div>
    </div>
  );
};

const Tab = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isActive: boolean }>(({ isActive }) => ({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.normal,
  fontWeight: isActive ? 600 : 400,
  marginRight: 16,
  padding: '0 8px 14px 8px',
  ...(isActive && { borderBottom: '4px solid #3892e5' }),
}));

const styles = {
  container: css({
    display: 'grid',
    padding: '16px 24px',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: 16,
    },
  }),
  infoContainer: css({
    justifySelf: 'center',
    maxWidth: 648,
    width: '100%',
  }),
  navigatorContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 24,
    gap: THEME.box.gaps.l,
  }),
  noAvailableFeatureContent: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    borderRadius: '0 0 5px 5px',
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    height: 88,
    justifyContent: 'center',
    textAlign: 'center',

    '& > div': {
      '& > div:nth-of-type(1)': {
        color: '#000',
        fontSize: THEME.font.sizes.normal,
        marginBottom: 8,
      },

      '& > a': {
        color: THEME.font.colors.blue.main,
        fontSize: THEME.font.sizes.normal,

        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  }),
  tabsContainer: css({
    backgroundColor: THEME.colors.white,
    borderBottom: '1px solid #dee5ec',
    borderRadius: '3px 3px 0 0',
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    flexWrap: 'wrap',
    padding: '16px 24px 0 24px',
  }),
};

export default Reports;
