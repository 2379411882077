import { format } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router';
import { css } from '@emotion/react';
import { EmailStatusIndicator } from '@src/components/atoms';
import { BackNavigator, ListLoading } from '@src/components/molecules';
import EmailForm, { dateFormat } from '@src/components/shared/EmailForm';
import { usePageLayout } from '@src/libs/hooks';
import { useGetEmailTemplateQuery, useGetPersonalUserDetailQuery } from '@src/graphql/hooks';
import { ROUTES } from '@src/shared/routes';
import { EmailTemplateStatus } from '@src/__generated__/globalTypes';

const Edit = () => {
  const { data } = useGetPersonalUserDetailQuery();
  const { isMobileView } = usePageLayout();
  const params = useParams<Record<any, string>>();
  const { data: dataEmailTemplate, loading } = useGetEmailTemplateQuery({
    variables: {
      input: {
        id: Number(params.id),
      },
    },
  });
  const getEmailTemplate = dataEmailTemplate?.getEmailTemplate;

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="Compose" to={ROUTES.MY_PAGE_EMAILS} />
          {getEmailTemplate?.status && <EmailStatusIndicator status={getEmailTemplate?.status} />}
        </div>
      )}

      <div css={styles.infoContainer}>
        {loading ? (
          <ListLoading />
        ) : (
          <EmailForm
            backUrl={ROUTES.MY_PAGE_EMAILS}
            fans={getEmailTemplate?.fans || []}
            id={getEmailTemplate?.id}
            isDraft={getEmailTemplate?.status === EmailTemplateStatus.DRAFT}
            message={getEmailTemplate?.message || ''}
            scheduleDate={getEmailTemplate?.scheduleDate || format(new Date(), dateFormat)}
            sender={getEmailTemplate?.sender || data?.getPersonalUserDetail?.email || ''}
            sendNow={getEmailTemplate ? !!getEmailTemplate?.sendNow : true}
            subject={getEmailTemplate?.subject || ''}
            tags={getEmailTemplate?.tags || []}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'grid',
    padding: '16px 24px',
  }),
  infoContainer: css({
    justifySelf: 'center',
    maxWidth: 648,
    width: '100%',
  }),
  navigatorContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,

    '& > div:nth-of-type(1)': {
      marginRight: 16,
    },
  }),
};

export default Edit;
