import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BroadcastForm } from '@src/components/shared';
import { DeliveryTimingType, LineMessageType, RecipientType } from '@src/__generated__/globalTypes';

const Add = () => {
  const lineBroadcastMessage = {
    deliveryTiming: {
      scheduleDate: '',
      type: DeliveryTimingType.IMMEDIATELY_SEND,
    },
    lineContent: [{ genId: uuidv4(), plainTextMessage: { text: '' }, type: LineMessageType.PLAIN_TEXT }],
    recipient: {
      tagNames: [],
      type: RecipientType.ALL,
    },
    title: '',
  };

  return <BroadcastForm lineBroadcastMessage={lineBroadcastMessage} />;
};

export default Add;
